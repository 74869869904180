import React from "react";
import { Link } from "gatsby";
import styled from "styled-components";
import arrowImg from "../../assets/img/arrow.svg";
import arrowLight from "../../assets/img/arrowLight.svg";

const LinkButtonInner = styled.div`
  .clicker {
    font-family: Visuelt-Medium;
    font-size: 18px;
    color: #FFFFFF;
    text-align: center;
    width: fit-content;
    max-width: 290px;
    margin: 0;
    display: block;
    background: #3A557C;
    border-radius: 5px;
    padding: 19px 24px;
    text-decoration: none;
    border: none;

    @media (max-width: 360px) {
      padding: 16px 20px;
      font-size: 17px;
      max-width: none;
      width: calc(100% - 40px);
    }

    &:hover {
      background: #2A4164;
      cursor: pointer;
    }
  }

  img {
    margin-left: 13px;
    
    @media (max-width: 360px) {
      margin-left: 7px;
    }
  }

  .arrow {
    text-align: left;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .light {
    background: #CBD3DE;
    color: #243A58;

    &:hover {
      background: #B5C4D9;
    }
  }
`;


const LinkButtonV2 = ({ to, text, isBig, func, arrow, light }) => {

  let innerObj = <>
    {text}
    {arrow && <>
      {light ?
        <img src={arrowLight} alt="" />
        :
        <img src={arrowImg} alt="" />
      }
    </>}
  </>

  let className = "clicker";

  if (arrow) {
    className = className + " arrow";
  }

  if (light) {
    className = className + " light";
  }


  return (
    <LinkButtonInner className="linkButton" isBig={isBig}>
      {func ?
        <button onClick={func} className={className}>
          {innerObj}
        </button>
      :
        <Link activeClassName="active" to={to} className={className}>
          {innerObj}
        </Link>
      }
    </LinkButtonInner>
  );
}

export default LinkButtonV2;


